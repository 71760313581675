import { AxiosError } from 'axios';
import { Currency, DatabaseId, Errors } from '../types/DataStructures';
import { Interpretation, Service } from '../types/Entities';
import { ServicesGetParams } from './interfaces';
import { apiClient } from './config/clients';
import { SERVICES_ENDPOINT } from './config/endpoints';
import { ApiDao, ApiQuery, applyLookup, businessParam, LookupsMap } from './config/helpers';
import { sanitizeInterpretation, sanitizeService } from './config/sanitizers';
import { hydrateInterpretation } from './config/hydrators';
import { BillRateType } from '../helpers/enum';

export const DEFAULT_SERVICES_LOOKUPS: LookupsMap<ServicesGetParams> = {
  bill_amount: 'lte',
};

export const getServicesDao: ApiDao<ServicesGetParams, any[]> = (params, lookups = DEFAULT_SERVICES_LOOKUPS) =>
  apiClient.get(SERVICES_ENDPOINT, { params: applyLookup(params, lookups) });

export const getServices: ApiQuery<ServicesGetParams, Service[]> = (params, lookups = DEFAULT_SERVICES_LOOKUPS) =>
  getServicesDao(params, lookups).then((res) => res.data.map((v) => sanitizeService(v)));

export interface ServicesPostPayload {
  provider: DatabaseId;
  bill_rate: number;
  bill_amount: Currency;
  bill_rate_type?: BillRateType;
  bill_rate_minutes_threshold?: number;
  bill_min_payment?: Currency;
  bill_no_show_fee: Currency;
  root?: DatabaseId;
}
export type ServicesPostResponse = DatabaseId;
export type ServicesPostError = Errors<ServicesPostPayload>;
export const createService = (payload: ServicesPostPayload) =>
  apiClient
    .post<ServicesPostResponse>(businessParam(SERVICES_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err: AxiosError<ServicesPostError>) => Promise.reject(err.response?.data));

export type ServicesPutPayload = ServicesPostPayload;

// Interpretation
export interface InterpretationGetParams extends ServicesGetParams {
  source_language_alpha3?: string;
  target_language_alpha3?: string;
}
export const getInterpretations: ApiQuery<InterpretationGetParams, Interpretation[]> = (
  params,
  lookups = DEFAULT_SERVICES_LOOKUPS,
) =>
  getServicesDao(params, lookups)
    .then((res) => res.data.map((v) => sanitizeInterpretation(v)))
    .then((dehydratedInterpretations) => Promise.all(dehydratedInterpretations.map((v) => hydrateInterpretation(v))));

export interface InterpretationPostPayload extends ServicesPostPayload {
  source_language_alpha3: string;
  target_language_alpha3: string;
}
export type InterpretationPostResponse = ServicesPostResponse;
export type InterpretationPostError = Errors<InterpretationPostPayload>;
export const createInterpretation = (payload: InterpretationPostPayload) => createService(payload);
