/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import { BEvent, BEventShallow, Booking } from '../types/Entities';
import { BookingStatus } from './datastructures';
import { AUTHORIZATION_STATUSES, CLINIC_AGENT_ROLES, COMPANY_TYPES, PAYER_TYPES } from './enum';
import { DehydratedBooking } from '../api/config/dehydrated_entities';
import { LightBEvent, LightBEventShallow, LightBooking } from '../api/config/light_entities';

/**
 * Checks if the payer state is valid for the given payer company type.
 * @param payer
 * @param payerCompany
 * @param payerCompanyType
 */
export const isEventPayerStateValid = (
  payer: BEvent['payer'] | null | undefined,
  payerCompany: BEvent['payer_company'] | null | undefined,
  payerCompanyType: BEvent['payer_company_type'] | null | undefined,
): boolean => {
  switch (payerCompanyType) {
    case PAYER_TYPES.CLINIC:
    case PAYER_TYPES.LAWYER:
      return !!payerCompany;

    case PAYER_TYPES.PATIENT:
      return !!payer;

    default:
      return !!(payer && payerCompany);
  }
};

/**
 * Computes the current status of a booking. This only works for bookings with a single patient.
 * @param booking
 * @param referenceEvent Event to be provided if the booking does not have any events
 */
export const computeBookingStatus = (
  booking?: Omit<DehydratedBooking, 'cCurrentStatus'>,
  referenceEvent?: Omit<BEvent, 'booking'>,
): BookingStatus => {
  if (!booking) {
    return {
      name: 'unknown',
      color: '#808080',
      step: '',
    };
  }
  // Pending initiate
  const event = booking.events?.[0] ?? referenceEvent;

  if (!event) {
    return {
      name: 'closed',
      color: '#00001c',
      step: '',
    };
  }

  const { payer_company_type } = event;

  if (payer_company_type === 'noPayer') {
    return {
      name: 'closed',
      color: '#00001c',
      step: '',
    };
  }

  const affiliation = event?.affiliates?.[0];
  const clinic = booking.companies.find(({ type }) => type === COMPANY_TYPES.CLINIC);
  const serviceRoot = booking.service_root;
  const medicalProvider = event?.agents?.find(({ role }) => role === CLINIC_AGENT_ROLES.MEDICAL_PROVIDER);

  if (
    !booking.public_id ||
    !affiliation ||
    !clinic ||
    !serviceRoot ||
    !booking.target_language_alpha3 ||
    !medicalProvider
  ) {
    return {
      name: 'pending',
      color: '#ff7976',
      step: 'initiate',
    };
  }

  // Pending intake
  const { date_of_injury, claim_number, claim_insurance_id, case_type, payer, payer_company } = event;

  if (
    !date_of_injury ||
    !claim_number ||
    ((case_type === 'commercialInsurance' || case_type === 'workersCompensation' || case_type === 'referredToAgency') &&
      !claim_insurance_id) ||
    !isEventPayerStateValid(payer, payer_company, payer_company_type)
  ) {
    return {
      name: 'pending',
      color: '#ff7976',
      step: 'intake',
    };
  }

  const report = event.reports[0];

  // Authorized reminders
  const approvedAuthorization = event.authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.ACCEPTED);
  if (approvedAuthorization && (!report || report.status !== 'COMPLETED')) {
    return {
      name: 'authorized',
      color: '#49dcbb',
      step: 'reminders',
    };
  }

  // Override reminders
  const overrideAuthorization = event.authorizations?.find(({ status }) => status === AUTHORIZATION_STATUSES.OVERRIDE);
  if (overrideAuthorization && (!report || report.status !== 'COMPLETED')) {
    return {
      name: 'override',
      color: '#eda93e',
      step: 'reminders',
    };
  }

  // Delivered follow ups

  if (report && report.status === 'COMPLETED') {
    return {
      name: 'delivered',
      color: '#538fff',
      step: 'follow_ups',
    };
  }

  // Booked
  return {
    name: 'booked',
    color: '#ffc55f',
    step: payer_company_type === COMPANY_TYPES.INSURANCE ? 'payer_authorize' : 'agency_authorize', // TODO what should this be for other types?
  };
};

export const extractBookingFromEvent = (event: BEvent): Booking => {
  const { booking } = event;
  if (!booking?.id) {
    console.error(`Event ${event.id} does not have a booking`);
  }

  const shallowEvent: BEventShallow = {
    ...event,
    booking: booking.id!,
  };

  return {
    ...booking,
    events: [shallowEvent],
  };
};

export const extractBookingFromLightEvent = (event: LightBEvent): LightBooking => {
  const { booking } = event;
  if (!booking?.id) {
    console.error(`Event ${event.id} does not have a booking`);
  }

  const shallowEvent: LightBEventShallow = {
    ...event,
    booking: booking.id!,
  };

  return {
    ...booking,
    events: [shallowEvent],
  };
};

/**
 * Computes the closest date from a list of events.
 * @param events
 */
export function getClosestDateFromEventList<T extends ClosestDateInput>(events: T[]): ClosestDateData<T> {
  if (events.length === 0) {
    return [null, undefined];
  }

  const now = moment();

  // This internal mapping helps us prevent computing the moment dates at every step
  const eventsArray = events.map((e) => ({
    ...e,
    _start_moment: moment(e.start_at),
    _end_moment: moment(e.end_at),
  }));

  // Get an ongoing event (any will make do)
  const currentEvent = eventsArray.find((e) => e._start_moment.isBefore(now) && e._end_moment.isAfter(now));
  if (currentEvent) {
    return [currentEvent, 'current'];
  }

  // Get the event that will happen the soonest from now
  const eventsSortedByStart = eventsArray.sort((e1, e2) => e1._start_moment.diff(e2._end_moment, 'minutes'));

  const nextEvent = eventsSortedByStart.filter((e) => e._start_moment.isAfter(now))[0];
  if (nextEvent) {
    return [nextEvent, 'next'];
  }

  // Get the event that most recently ended
  const eventsSortedByEnd = eventsArray.sort((e1, e2) => e2._end_moment.diff(e1._end_moment, 'minutes'));

  const lastEvent = eventsSortedByEnd.filter((e) => e._end_moment.isBefore(now))[0];
  if (lastEvent) {
    return [lastEvent, 'last'];
  }

  // This should not be reached; no event found
  console.error('Failed to find the closest event');
  return [null, undefined];
}

type ClosestDateInput = { start_at: Date; end_at: Date };
export type ClosestDateData<T> = [closestEvent: T | null, closestEventState: 'current' | 'next' | 'last' | undefined];
