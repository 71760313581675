import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'src/app/context/ModeContext';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import { BUTTON_STATE, CATEGORY_PREFIXES } from '../../../../../app/helpers/enum';
import { filterCategoriesByPrefix } from '../../../../../app/helpers/manipulation';
import { Interpretation, Language, ServiceRoot } from '../../../../../app/types/Entities';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { useForm } from '../../../../../app/hooks';
import { FormInput } from '../../../../../components/Form/FormInput';
import { useCacheLanguages } from '../../../../../app/api/cache/hooks';
import './ServiceField.scss';

export interface ServiceFieldProps {
  formPrefix: string;
  showService: boolean;
  setShowService: (show: boolean) => void;

  serviceRoot: ServiceRoot<Interpretation> | null;
  setServiceRoot: (root: ServiceRoot<Interpretation> | null) => void;
  serviceRoots: ServiceRoot<Interpretation>[] | undefined;
  isServiceRootLoading: boolean;

  setService: (service: Interpretation | null) => void;

  targetLanguage: Language | null;
  setTargetLanguage: (language: Language | null) => void;
}

type ServiceRootExtended = ServiceRoot<Interpretation> & {
  certification: ServiceRoot<Interpretation>['categories'][0] | undefined;
  modality: ServiceRoot<Interpretation>['categories'][0] | undefined;
};

const extendServiceRoot = (root: ServiceRoot<Interpretation>): ServiceRootExtended => ({
  ...root,
  certification: filterCategoriesByPrefix(root?.categories, CATEGORY_PREFIXES.CERTIFICATION)?.[0],
  modality: filterCategoriesByPrefix(root?.categories, CATEGORY_PREFIXES.MODALITY)?.[0],
});

const translationArray = ['booking', 'languages'];

function ServiceField(props: ServiceFieldProps) {
  const {
    formPrefix,
    showService,
    setShowService,
    serviceRoot,
    setServiceRoot,
    serviceRoots,
    isServiceRootLoading,
    setService,
    targetLanguage,
    setTargetLanguage,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);
  const { userRoleView } = useAppContext();

  const serviceRootsExtended = (): ServiceRootExtended[] => serviceRoots?.map(extendServiceRoot) ?? [];

  const serviceRootExtended = serviceRoot && extendServiceRoot(serviceRoot);

  // Query
  const certificationCategory = filterCategoriesByPrefix(serviceRoot?.categories, CATEGORY_PREFIXES.CERTIFICATION)?.[0];
  const languages = useCacheLanguages(true);
  const modalityCategory = filterCategoriesByPrefix(serviceRoot?.categories, CATEGORY_PREFIXES.MODALITY)?.[0];

  // Display
  const label = <h5>{t`service`} *</h5>;

  const display = (
    <Row className="gx-2">
      <Col xs={12} xxl={4}>
        <FormSelect
          id={p`language`}
          options={languages}
          value={targetLanguage}
          onChange={(_event, entry) => {
            setService(null);
            setTargetLanguage(entry ?? null);
          }}
          renderInput={(params) => <TextField placeholder={t`selectLanguage`} {...params} />}
          getOptionLabel={({ name }) => name}
          disabled={userRoleView === 'provider'}
        />
      </Col>
      <Col xs={12} xxl={8}>
        <FormSelect<ServiceRootExtended>
          id={p`serviceRootName`}
          loading={isServiceRootLoading}
          options={serviceRootsExtended()}
          groupBy={(root) => root.modality?.description ?? 'Unknown modality'}
          value={serviceRootExtended}
          onChange={(_event, root) => {
            setService(null);
            setServiceRoot(root);
            if (!root) {
              setShowService(false);
            }
          }}
          renderInput={(params) => <TextField placeholder={t`selectServiceName`} {...params} />}
          getOptionLabel={(root) => root.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={userRoleView === 'provider'}
        />
      </Col>
    </Row>
  );

  const onCancel = () => {
    setShowService(false);
  };

  return (
    <FormExpandableField
      formPrefix={formPrefix}
      isOpen={showService}
      toggleOpen={setShowService}
      openButtonState={!serviceRoot || userRoleView === 'provider' ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      label={label}
      display={display}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col>
          <FormInput className="service-field" disabled readOnly label={t`rootService`} value={t`interpretation`} />
        </Col>
        <Col>
          <FormInput disabled readOnly label={t`modality`} value={modalityCategory?.description ?? ''} />
        </Col>
        <Col>
          <FormInput disabled readOnly label={t`certification`} value={certificationCategory?.description ?? ''} />
        </Col>
      </Row>
      <Row className="gx-2">
        <Col>
          <FormInput disabled readOnly label={t`description`} value={serviceRoot?.description ?? ''} />
        </Col>
      </Row>
    </FormExpandableField>
  );
}

export default ServiceField;
