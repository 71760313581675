import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Badge } from '@mui/material';
import NoteInput from 'src/pages/BookingsPage/Onboarding/Fields/NotesListField/NoteInput';
import FormExpandableListField from '../../../../../components/Form/FormExpandableListField';
import { useAppSelector, useForm } from '../../../../../app/hooks';
import { Note } from '../../../../../app/types/Entities';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import NoteDisplay from '../NoteDisplay/NoteDisplay';
import { selectUser } from '../../../../../app/stores/authSlice';
import { getUser, MUTATION_KEYS } from '../../../../../app/api';

export interface NotesListFieldProps {
  formPrefix: string;
  showNotesList: boolean;
  setShowNotesList: (display: boolean) => void;
  labelText: string;
  notesList: Note[];
  pushNote: (item: Note) => void;
  replaceNoteAt: (index: number, item: Note) => void;
  removeNoteAt: (index: number) => void;
}

const translationArray = ['booking', 'forms', 'languages'];

function NotesListField(props: NotesListFieldProps) {
  const { formPrefix, showNotesList, setShowNotesList, labelText, notesList, pushNote, replaceNoteAt, removeNoteAt } =
    props;

  // Utils
  const { user_id } = useAppSelector(selectUser) ?? {};
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  const [createMode, setCreateMode] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>('');

  const { data: user } = useQuery(
    [MUTATION_KEYS.USERS, user_id],
    () => (user_id !== undefined ? getUser(user_id) : undefined),
    {
      enabled: user_id !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  const buildNote = (): Note => {
    return {
      created_at: new Date(),
      created_by: user!.id!,
      created_by_first_name: user?.first_name,
      created_by_last_name: user?.last_name,
      text: noteText,
      uuid: uuidv4(),
    };
  };

  const onAdd = () => {
    setCreateMode(true);
    setShowNotesList(true);
  };

  // Display
  const label = (
    <Badge color="primary" badgeContent={notesList.length} showZero variant="counterBadge">
      <h5>{labelText}</h5>
    </Badge>
  );

  const onCancel = () => {
    setShowNotesList(false);
  };

  return (
    <FormExpandableListField
      formPrefix={p`notes-list`}
      isOpen={showNotesList}
      toggleOpen={setShowNotesList}
      onAdd={onAdd}
      addLabel={t`tooltip.addNote`}
      openButtonState={notesList.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showNotesList ? t`tooltip.hideNotes` : t`tooltip.viewNotes`}
      defaultDisplay={t`noNotesToShow`}
      label={label}
      onCancel={onCancel}
    >
      {createMode && (
        <NoteInput
          onDiscard={() => {
            setCreateMode(false);
            setShowNotesList(false);
            setNoteText('');
          }}
          onSubmit={() => {
            pushNote({
              ...buildNote(),
            });
            setCreateMode(false);
            setNoteText('');
          }}
          noteText={noteText}
          setNoteText={setNoteText}
        />
      )}

      {notesList
        .slice()
        .reverse()
        .map((note, index) => {
          const newIndex = notesList.length - index - 1;
          return (
            <NoteDisplay
              key={`${note.uuid}${showNotesList}`}
              formPrefix={p`note-` + newIndex}
              note={note}
              removeButtonTooltip={t`tooltip.removeNote`}
              editButtonTooltip={t`tooltip.editNote`}
              setText={(text: string) =>
                replaceNoteAt(newIndex, {
                  ...note,
                  text,
                })
              }
              onRemove={() => removeNoteAt(newIndex)}
            />
          );
        })}
    </FormExpandableListField>
  );
}

export default NotesListField;
