const config_booking = {
  columnNames: ['DAY', 'STATUS', 'HOUR', 'BOOKING ID', 'PATIENT', 'CLINIC'],
  keys: ['start_date', 'booking_status', 'start_hour', 'public_id', 'patient', 'clinic'],
  fileName: 'booking_list.xlsx',
  sheetName: 'Booking List',
};

const config_interpreters = {
  columnNames: ['FIRST NAME', 'LAST NAME', 'PHONE', 'EMAIL'],
  keys: ['first_name', 'last_name', 'phone', 'email'],
  fileName: 'interpreter_list.xlsx',
  sheetName: 'Interpreter List',
};

const config_reports = {
  columnNames: [
    'first_name',
    'last_name',
    'date_of_birth',
    'phone_contact',
    'email_contact',
    'fax_contact',
    'address',
    'unit_number',
    'city',
    'state',
    'country',
    'zip',
    'public_id',
    'date',
    'arrive_time',
    'start_time',
    'end_time',
    'date_of_injury',
    'payer_company_type',
    'payer_company_name',
    'payer_company_address',
    'payer_company_city',
    'payer_company_state',
    'payer_company_send_method',
    'provider',
    'claim_number',
    'clinic',
    'clinic_address',
    'clinic_unit_number',
    'clinic_city',
    'clinic_state',
    'clinic_country',
    'clinic_zip',
    'send_method',
    'notes',
    'contacts',
    'language',
    'type_of_appointment',
    'interpreter',
    'modality',
    'status_report',
    'authorized',
    'auth_by',
    'operators_first_name',
    'operators_last_name',
    'certification',
    'certification_number',
    'price',
  ],
  keys: [
    'first_name',
    'last_name',
    'date_of_birth',
    'phone_contact',
    'email_contact',
    'fax_contact',
    'address',
    'unit_number',
    'city',
    'state',
    'country',
    'zip',
    'public_id',
    'date',
    'arrive_time',
    'start_time',
    'end_time',
    'date_of_injury',
    'payer_company_type',
    'payer_company_name',
    'payer_company_address',
    'payer_company_city',
    'payer_company_state',
    'payer_company_send_method',
    'provider',
    'claim_number',
    'clinic',
    'clinic_address',
    'clinic_unit_number',
    'clinic_city',
    'clinic_state',
    'clinic_country',
    'clinic_zip',
    'send_method',
    'notes',
    'contacts',
    'language',
    'type_of_appointment',
    'interpreter',
    'modality',
    'status_report',
    'authorized',
    'auth_by',
    'operators_first_name',
    'operators_last_name',
    'certification',
    'certification_number',
    'price',
  ],
  fileName: 'report.xlsx',
  sheetName: 'Report List',
};

export { config_booking, config_interpreters, config_reports };
