import { AxiosError } from 'axios';
import { UsersGetParams } from './users';
import { Currency, DatabaseId, Errors } from '../types/DataStructures';
import { Interpreter, Provider, ProviderCertification, ServiceArea } from '../types/Entities';
import { sanitizeInterpreter, sanitizeProvider } from './config/sanitizers';
import { apiClient } from './config/clients';
import { PROVIDERS_ENDPOINT } from './config/endpoints';
import { ApiQuery, BUSINESS_NAME, businessParam, pathParam } from './config/helpers';
import { ServicesGetParams } from './interfaces';
import { NotePayload } from './payloads';
import { hydrateInterpreter } from './config/hydrators';

// GET
export interface ProvidersGetParams extends UsersGetParams {
  services?: Omit<ServicesGetParams, 'provider'>;

  page?: number;
  page_size?: number;
}

export const getProviders: ApiQuery<ProvidersGetParams, Provider[]> = (params) =>
  apiClient
    .get<any[]>(businessParam(PROVIDERS_ENDPOINT), {
      params,
    })
    .then((res) => res.data.map((v) => sanitizeProvider(v)));

export const getInterpreters: ApiQuery<ProvidersGetParams, Interpreter[]> = (params) =>
  apiClient
    .get<any[]>(businessParam(PROVIDERS_ENDPOINT), {
      params,
    })
    .then((res) => res.data.map((v) => sanitizeInterpreter(v)))
    .then((dehydratedInterpreters) => Promise.all(dehydratedInterpreters.map((v) => hydrateInterpreter(v))));

export const getInterpretersPaginated: ApiQuery<
  ProvidersGetParams,
  { count: number; next: string | null; previous: string | null; results: Interpreter[] }
> = (params) =>
  apiClient
    .get<any>(businessParam(PROVIDERS_ENDPOINT), {
      params,
    })
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      results: data.results.map(sanitizeInterpreter),
    }))
    .then(async (data) => ({
      ...data,
      results: await Promise.all(data.results.map(hydrateInterpreter)),
    }));

export const getProvider = (id: DatabaseId) =>
  apiClient.get<Provider>(pathParam(PROVIDERS_ENDPOINT, id)).then((res) => sanitizeProvider(res.data));

export interface ProvidersPostPayload {
  companies: DatabaseId[];
  notes?: NotePayload[];
  min_weekly_assignments?: number;
  parking_extra?: boolean;
  parking_ticket?: boolean;
  bonus_extra?: boolean;
  mileage_extra?: boolean;
  mileage_rate?: Currency;
  certifications?: ProviderCertification[];
  serviceAreas?: ServiceArea[];
}

export type ProvidersPostResponse = DatabaseId;
export type ProvidersPostError = Errors<ProvidersPostPayload>;
export const createProvider = (payload: ProvidersPostPayload) =>
  apiClient
    .post<{ provider_id: DatabaseId }>(PROVIDERS_ENDPOINT, payload)
    .then<ProvidersPostResponse>((res) => res.data.provider_id)
    .catch((err: AxiosError<ProvidersPostError>) => Promise.reject(err.response?.data));

// PUT
export type ProvidersPutPayload = ProvidersPostPayload;
export type ProvidersPutResponse = unknown;
export type ProvidersPutError = Errors<ProvidersPutPayload>;
export const updateProvider = (providerId: DatabaseId, payload: ProvidersPutPayload) =>
  apiClient
    .put<ProvidersPutResponse>(pathParam(PROVIDERS_ENDPOINT, providerId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<ProvidersPutError>) => Promise.reject(err.response?.data));
