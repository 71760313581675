import { createContext, useContext, useState, ReactNode, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../hooks';
import { selectUser } from '../stores/authSlice';
import { getUser, MUTATION_KEYS } from '../api';
import { User } from '../types/Entities';

interface AppRoleContextProps {
  userRoleView: 'operator' | 'provider' | null;
  setUserRoleView: (role: 'operator' | 'provider' | null) => void;
  logInUser: Omit<User, never> | undefined;
}

const AppContext = createContext<AppRoleContextProps | null>(null);

export function ContextProvider({ children }: { children: ReactNode }) {
  // utils
  const user = useAppSelector(selectUser);

  // queries
  const { data: logInUser } = useQuery([MUTATION_KEYS.USERS, user?.user_id], () => getUser(user?.user_id), {
    refetchOnWindowFocus: false,
  });

  const [userRoleView, setUserRoleView] = useState<'operator' | 'provider' | null>(() => {
    // Restore userRoleView from local storage
    const savedRole = sessionStorage.getItem('userRoleView');
    return savedRole ? (savedRole as 'operator' | 'provider' | null) : null;
  });

  // Set default role view based on user roles
  useEffect(() => {
    if (user) {
      if (user.roles.operator && !user.roles.provider) {
        setUserRoleView('operator');
      } else if (user.roles.provider && !user.roles.operator) {
        setUserRoleView('provider');
      }
    }
  }, [setUserRoleView, user]);

  useEffect(() => {
    // Save userRoleView to local storage whenever it changes
    if (userRoleView) {
      sessionStorage.setItem('userRoleView', userRoleView);
    }
  }, [userRoleView]);

  const value = useMemo(
    () => ({ userRoleView, setUserRoleView, logInUser }),
    [userRoleView, setUserRoleView, logInUser],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
