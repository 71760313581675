interface ExtractStatus {
  status: string;
}

function validatorStatus(type: ExtractStatus) {
  if (type.status === 'pending') {
    return {
      color: '#ff7976',
      name: type.status,
      step: 'intake',
    };
  }

  if (type.status === 'booked') {
    return {
      name: type.status,
      color: '#ffc55f',
      step: 'payer_authorize | agency_authorize',
    };
  }

  if (type.status === 'authorized') {
    return {
      name: type.status,
      color: '#49dcbb',
      step: 'reminders | assign_support | deliver_service',
    };
  }

  if (type.status === 'override') {
    return {
      name: type.status,
      color: '#eda93e',
      step: 'reminders | assign_support | deliver_service',
    };
  }

  if (type.status === 'delivered') {
    return {
      name: type.status,
      color: '#538fff',
      step: 'follow_ups | service_report',
    };
  }

  if (type.status === 'closed') {
    return {
      name: type.status,
      color: '#00001c',
      step: 'closed',
    };
  }

  return {
    color: '#00000',
    name: 'unknown',
    step: 'intake',
  };
}

export default validatorStatus;
