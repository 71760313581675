/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, FormText, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { CLINIC_AGENT_ROLES, TITLES } from 'src/app/helpers/enum';
import { SUFFIXES } from 'src/app/helpers/collections';
import { toast } from 'react-toastify';
import FormSelectCreatable from 'src/components/Form/FormSelectCreatable/FormSelectCreatable';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { useAppContext } from 'src/app/context/ModeContext';
import {
  buildUsername,
  flattenContacts,
  mapArrayForSelect,
  mapObjectForSelect,
  unflattenContacts,
  userToString,
} from '../../../../app/helpers/mappers';
import { FormInput } from '../../../../components/Form/FormInput';
import FormWrapper from '../../../../components/Form/FormWrapper';
import FlatContactList from './FlatContactList/FlatContactList';
import FormExpandableField from '../../../../components/Form/FormExpandableField';
import { useForm, useStateArray, useStateDict } from '../../../../app/hooks';
import {
  AgentsGetParams,
  createUserComplex,
  getCompanyWithRoles,
  MUTATION_KEYS,
  updateUserComplex,
  UsersPostErrorComplex,
  UsersPostPayloadComplex,
  UsersPostResponseComplex,
  UsersPutErrorComplex,
  UsersPutPayloadComplex,
  UsersPutResponseComplex,
} from '../../../../app/api';
import { Agent, Clinic, Company, Doctor, FlatContact, ParentCompany } from '../../../../app/types/Entities';
import { ErrorsAlert } from '../../../../components/ErrorsAlert';
import { BUTTON_STATE } from '../../../../app/helpers/enum';

export interface MedicalProviderFieldProps {
  showMedicalProviderField: boolean;
  setShowMedicalProviderField: (display: boolean) => void;
  medicalProvider: Doctor | null;
  setMedicalProvider: (medicalProvider: Doctor | null) => void;
  clinic: Clinic | null;

  onUpdateSuccess?: (updatedMedicalProvider?: Doctor) => void;
}

const formPrefix = 'medicalProvider';
const translationArray = ['booking', 'forms', 'languages'];

function MedicalProviderField(props: MedicalProviderFieldProps) {
  const {
    showMedicalProviderField,
    setShowMedicalProviderField,
    medicalProvider,
    setMedicalProvider,
    clinic,
    onUpdateSuccess,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);
  const { userRoleView } = useAppContext();

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<UsersPostErrorComplex & UsersPutErrorComplex>({});

  const [createMode, setCreateMode] = useState<boolean>(false);

  const [title, setTitle] = useState<string | undefined>();
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [suffix, setSuffix] = useState<string | undefined>();
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  const prefillForm = () => {
    setTitle(medicalProvider?.title ?? '');
    setFirstName(medicalProvider?.first_name ?? '');
    setLastName(medicalProvider?.last_name ?? '');
    setSuffix(medicalProvider?.suffix ?? '');
    setFlatContacts(flattenContacts(medicalProvider?.contacts) ?? []);
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalProvider, setFlatContacts]);

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // Query
  const [medicalProviderFilters] = useStateDict<AgentsGetParams>({ role: CLINIC_AGENT_ROLES.MEDICAL_PROVIDER });
  const {
    data: queryCompany,
    isLoading: isQueryCompanyLoading,
    refetch: refetchCompany,
  } = useQuery(
    [MUTATION_KEYS.COMPANIES, clinic?.id],
    () => (clinic?.id ? getCompanyWithRoles(clinic.id) : Promise.reject()),
    { refetchOnWindowFocus: false },
  );
  const medicalProviders = queryCompany?.agents?.filter((agent) => agent.role === medicalProviderFilters.role);

  // On saving
  const { mutateAsync: mutateAsyncCreateUserComplex } = useMutation<
    UsersPostResponseComplex,
    UsersPostErrorComplex,
    UsersPostPayloadComplex
  >(createUserComplex);

  const { mutateAsync: mutateAsyncUpdateUserComplex } = useMutation<
    UsersPutResponseComplex,
    UsersPutErrorComplex,
    Parameters<typeof updateUserComplex>
  >((args) => updateUserComplex(...args));

  // On save functions
  // Validate
  const validate = () => {
    const validationErrors = [];

    if (!firstName) {
      validationErrors.push(t`errors.noFirstName`);
    }

    if (!lastName) {
      validationErrors.push(t`errors.noLastName`);
    }

    if (!createMode && !medicalProvider?.id) {
      validationErrors.push(t`expectedAgentId`);
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  // On create
  const createMedicalProvider = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadUsername = buildUsername(firstName!, lastName!, new Date());
    const payloadEmailFlatContact = flatContacts.find(({ type, data }) => type === 'email' && data) as Extract<
      FlatContact,
      { type?: 'email' }
    >;

    const companies = clinic ? [clinic.id!] : [];

    const payload: UsersPostPayloadComplex = {
      username: payloadUsername,
      email: payloadEmailFlatContact?.data.address || '',
      contacts: unflattenContacts(flatContacts),
      first_name: firstName!,
      last_name: lastName!,
      suffix: suffix || '',
      title: title || '',
      _agent_data: {
        companies,
        role: CLINIC_AGENT_ROLES.MEDICAL_PROVIDER,
      },
      _recipient_data: {},
      _requester_data: {
        companies,
      },
    };

    // Display toast for the creation process
    toast
      .promise(mutateAsyncCreateUserComplex(payload), {
        pending: t`progress.medicalProviderCreate.started` as string,
        success: t`progress.medicalProviderCreate.success` as string,
        error: t`progress.medicalProviderCreate.error` as string,
      })
      .catch((err: UsersPostErrorComplex) => {
        setErrors(err);
        console.error('Errors when creating medical provider', err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(async (idsCollection) => {
        const createdMedicalProviderId = idsCollection.agent_id;
        return [await refetchCompany(), createdMedicalProviderId] as const;
      })
      .then(([refetchData, createdMedicalProviderId]) => {
        const { data: newCompany } = refetchData;
        const newlyCreatedMedicalProvider = newCompany?.agents?.find(
          (newMedicalProvider) => newMedicalProvider.id === createdMedicalProviderId,
        );
        setShowMedicalProviderField(false);
        setCreateMode(false);
        onUpdateSuccess?.(newlyCreatedMedicalProvider);
      });
  };

  // On update
  const updateMedicalProvider = () => {
    setErrors({});

    if (!validate()) {
      return;
    }

    const companies = medicalProvider?.companies
      ? medicalProvider.companies
          .map((company: number | Company<ParentCompany>) => {
            if (typeof company === 'number') {
              return company;
            }

            if (company !== undefined && company.id !== undefined) {
              return company.id;
            }

            return null;
          })
          .filter((id: number | null): id is number => id !== null)
      : [];

    const payload: UsersPutPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      first_name: firstName!,
      last_name: lastName!,
      suffix: suffix || '',
      title: title || '',
      email: medicalProvider?.email || '',
      _agent_data: {
        companies,
        role: medicalProvider!.role,
      },
      _requester_data: {
        companies,
      },
    };

    toast
      .promise(mutateAsyncUpdateUserComplex([medicalProvider!.user_id!, payload]), {
        pending: t`progress.medicalProviderUpdate.started` as string,
        success: t`progress.medicalProviderUpdate.success` as string,
        error: t`progress.medicalProviderUpdate.error` as string,
      })
      .catch((err) => {
        if (err) setErrors(err);
        console.error('Errors when updating medicalProvider', err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => refetchCompany())
      .then((refetchData) => {
        const { data: newCompany } = refetchData;
        const updatedMedicalProvider = newCompany?.agents?.find(
          (newMedicalProvider) => newMedicalProvider.id === medicalProvider?.id,
        );
        onUpdateSuccess?.(updatedMedicalProvider);
      });
  };

  const onSave = () => (createMode ? createMedicalProvider() : updateMedicalProvider());

  // Display
  const label = <h5>{t`medicalProvider`} *</h5>;

  const display = (
    <FormSelectCreatable<Agent>
      fullWidth
      id={p`medicalProvider`}
      options={medicalProviders ?? []}
      value={createMode ? null : medicalProvider}
      loading={isQueryCompanyLoading}
      disabled={createMode || !clinic || userRoleView === 'provider'}
      clearOnBlur
      renderInput={(params) => (
        <TextField {...params} label={createMode ? t`newMedicalProvider` : t`selectMedicalProvider`} />
      )}
      getCreatedOptionLabel={() => t`tooltip.addMedicalProvider`}
      getSelectedOptionLabel={(agent) => userToString(agent)}
      onCreateOption={(newValue) => {
        setShowMedicalProviderField(!createMode);
        setCreateMode(!createMode);

        const [newFirstName, newLastName] = newValue.split(' ', 2);
        setFirstName(newFirstName);
        setLastName(newLastName);
      }}
      onSelectOption={(option) => setMedicalProvider(option as Agent)}
      getOptionValue={(option) => option.id}
    />
  );

  const onCancel = () => {
    setShowMedicalProviderField(false);
    setCreateMode(false);
    prefillForm();
  };

  const footer = (
    <Row>
      <Col>
        <Button className="action-button me-4" color="submit" onClick={onSave} block>
          {t`saveMedicalProvider`}
        </Button>
      </Col>
      <Col>
        <Button className="action-button" color="cancel" onClick={onCancel} block>
          {t`cancel`}
        </Button>
      </Col>
      <Col>
        <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
      </Col>
    </Row>
  );

  const onAdd = () => {
    setMedicalProvider(null);
    setShowMedicalProviderField(!createMode);
    setCreateMode(!createMode);
  };

  return (
    <FormExpandableField
      formPrefix={p`medical-provider`}
      isOpen={showMedicalProviderField}
      toggleOpen={setShowMedicalProviderField}
      canClose={!createMode}
      onAdd={onAdd}
      addButtonState={clinic ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.DISABLED}
      openButtonState={
        !medicalProvider || createMode || userRoleView === 'provider' ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE
      }
      openButtonTooltip={showMedicalProviderField ? t`tooltip.hideMedicalProvider` : t`tooltip.viewMedicalProvider`}
      label={label}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col md={12} xl={12} xxl={2}>
          <FormSelect
            id={p`title`}
            options={titleOptions}
            value={titleOptions.find((o) => o.value === title)}
            onChange={(_event, titleOption) => setTitle(titleOption.value)}
            renderInput={(params) => <TextField placeholder={t`title`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`firstName`} *`}
            value={firstName ?? ''}
            onChange={(e) => setFirstName(e.target.value)}
            errors={errors.first_name}
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`lastName`} *`}
            value={lastName ?? ''}
            onChange={(e) => setLastName(e.target.value)}
            errors={errors.last_name}
          />
        </Col>
        <Col md={12} xl={12} xxl={2}>
          <FormSelect
            id={p`suffix`}
            options={suffixOptions}
            value={suffixOptions.find((o) => o.value === suffix)}
            onChange={(_event, suffixOption) => setSuffix(suffixOption.value)}
            renderInput={(params) => <TextField placeholder={t`suffix`} {...params} />}
            disableClearable
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={t`contactInformation`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>

      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <ErrorsAlert errorsArray={errors.validation_errors} />
    </FormExpandableField>
  );
}

export default MedicalProviderField;
