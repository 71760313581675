import './HomePage.scss';
import { Button, Col, FormText, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/app/context/ModeContext';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../app/stores/authSlice';
import MiddleCenterAlignedContainer from '../../components/MiddleCenterAlignedContainer';
import { getAPIVersion, getVersion } from '../../app/api/version';
import { MUTATION_KEYS } from '../../app/api';

export default function HomePage() {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { setUserRoleView } = useAppContext();

  const { data: apiVersion } = useQuery([MUTATION_KEYS.VERSION], getAPIVersion);

  return (
    <>
      <FormText color="muted" className="version-note">
        <p>{t('frontend-version', { version: getVersion() ?? t`unknown` })}</p>
        <p>{t('api-version', { version: apiVersion ?? t`unknown` })}</p>
      </FormText>
      <MiddleCenterAlignedContainer maxWidth>
        <Nav pills className="main-menu">
          <NavItem>
            {user ? (
              <section className="logged-user-info">
                <p>Welcome, {user?.first_name}</p>
              </section>
            ) : (
              <NavLink tag={Link} to="/login">
                Login
              </NavLink>
            )}
          </NavItem>
          <Row>
            {user?.roles.operator && user?.roles.provider ? (
              <>
                <Col>
                  <NavItem>
                    <Button
                      className="home-page-button"
                      color="primary"
                      disabled={!user}
                      onClick={() => {
                        navigate('/bookings/list');
                        setUserRoleView('operator');
                      }}
                    >
                      View as Operator
                    </Button>
                  </NavItem>
                </Col>
                <Col>
                  <NavItem>
                    <Button
                      className="home-page-button"
                      color="primary"
                      disabled={!user}
                      onClick={() => {
                        navigate('/bookings/list');
                        setUserRoleView('provider');
                      }}
                    >
                      View as Interpreter
                    </Button>
                  </NavItem>
                </Col>
              </>
            ) : null}
          </Row>
        </Nav>
      </MiddleCenterAlignedContainer>
    </>
  );
}
