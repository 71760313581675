import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { toast, ToastContainer } from 'react-toastify';
import { createTheme, PaletteColorOptions, ThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import SignInPage from './pages/SignInPage/SignInPage';
import { useAppSelector } from './app/hooks';
import HomePage from './pages/HomePage/HomePage';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { selectUser } from './app/stores/authSlice';
import BookingsPage from './pages/BookingsPage/BookingsPage';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AppHeader from './components/AppHeader/AppHeader';
import 'react-toastify/dist/ReactToastify.css';
import { PREFERRED_TOAST_AUTOCLOSE } from './app/helpers/constants';
import SignUpPage from './pages/SignUpPage/SingUpPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { queryClient } from './app/api';
import OnboardingPage from './pages/OnboardingPage/OnboardingPage';
import AdminPage from './pages/AdminPage/AdminPage';
import { useFilterEvents } from './app/hooks/useFilterEvents';
import { ContextProvider } from './app/context/ModeContext';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    formLabel: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    formLabel?: React.CSSProperties;
  }

  interface CustomPalette {
    pending: PaletteColorOptions;
    accepted: PaletteColorOptions;
    rejected: PaletteColorOptions;
    referred: PaletteColorOptions;
    override: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formLabel: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    counterBadge: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    pending: true;
    accepted: true;
    rejected: true;
    referred: true;
    override: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    pending: true;
    accepted: true;
    rejected: true;
    referred: true;
    override: true;
  }
}

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#00a2b8',
      light: '#2fbed2',
      dark: '#008394',
    },
    pending: defaultTheme.palette.augmentColor({ color: { main: '#ff0' } }),
    accepted: defaultTheme.palette.augmentColor({ color: { main: '#49dcbb' } }),
    rejected: defaultTheme.palette.augmentColor({ color: { main: '#f00' } }),
    referred: defaultTheme.palette.augmentColor({ color: { main: '#00f' } }),
    override: defaultTheme.palette.augmentColor({ color: { main: '#eda93e' } }),
  },
  typography: {
    formLabel: {
      ...defaultTheme.typography.button,
      fontWeight: 'bold',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          marginBottom: 2,
          '& .Mui-disabled': {
            backgroundColor: '#E9ECEF',
            borderColor: '#CED4DA',
          },
        },
      },
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: 'counterBadge' },
          style: {
            '& .MuiBadge-badge': {
              right: -14,
              top: 13,
              border: '2px solid #fff',
              padding: '0 4px',
            },
          },
        },
      ],
    },
  },
});

export default function App() {
  const user = useAppSelector(selectUser);
  const [eventFilters, setEventFilters] = useFilterEvents();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary location="App">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ContextProvider>
              <Container className="m-0 p-0">
                <Row className="header-row">
                  <AppHeader eventFilters={eventFilters} />
                </Row>

                <Row className="content-row m-0 p-0">
                  <Col className="m-0 p-0">
                    <React.Suspense fallback={<LoadingPage />}>
                      <Routes>
                        {user ? (
                          <>
                            <Route path="/" element={<Navigate replace to="/home" />} />
                            <Route
                              path="/bookings/calendar"
                              element={<BookingsPage setEventFiltersProps={setEventFilters} />}
                            />
                            <Route
                              path="/bookings/list"
                              element={<BookingsPage setEventFiltersProps={setEventFilters} />}
                            />
                            <Route path="/onboarding" element={<OnboardingPage />} />
                            {user.roles.admin && <Route path="/admin" element={<AdminPage user={user} />} />}
                          </>
                        ) : (
                          <>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/login" element={<SignInPage />} />
                            <Route path="/register" element={<SignUpPage />} />
                          </>
                        )}
                        <Route path="/home" element={<HomePage />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </React.Suspense>
                  </Col>
                </Row>
              </Container>

              <ReactQueryDevtools initialIsOpen={false} />
              <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={PREFERRED_TOAST_AUTOCLOSE} />
            </ContextProvider>
          </LocalizationProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
