import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { useAppContext } from 'src/app/context/ModeContext';
import './BookingStatus.scss';
import BookingsFilterCheck from '../BookingsFilterCheck/BookingsFilterCheck';
import BookingsFiltersList from '../BookingsFiltersList/BookingsFiltersList';

const translationArray = ['calendar'];

function BookingStatus({
  setItemsIncludedList,
  setItemsExcludedList,
}: {
  setItemsIncludedList: Dispatch<SetStateAction<string[]>>;
  setItemsExcludedList: Dispatch<SetStateAction<string[]>>;
}) {
  const { t } = useTranslation(translationArray);
  const { userRoleView } = useAppContext();

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <BookingsFiltersList color="#000000" label={t`sidebar.bookingStatus.title`}>
          <BookingsFilterCheck
            color="#ff7976"
            label={t`sidebar.bookingStatus.pending`}
            value="pending"
            setItemsIncludedList={setItemsIncludedList}
            setItemsExcludedList={setItemsExcludedList}
          />
          <BookingsFilterCheck
            color="#ffc55f"
            label={t`sidebar.bookingStatus.booked`}
            value="booked"
            setItemsIncludedList={setItemsIncludedList}
            setItemsExcludedList={setItemsExcludedList}
          />
          <BookingsFilterCheck
            color="#eda93e"
            label={t`sidebar.bookingStatus.override`}
            value="override"
            setItemsIncludedList={setItemsIncludedList}
            setItemsExcludedList={setItemsExcludedList}
          />
          <BookingsFilterCheck
            color="#49dcbb"
            label={t`sidebar.bookingStatus.authorized`}
            value="authorized"
            setItemsIncludedList={setItemsIncludedList}
            setItemsExcludedList={setItemsExcludedList}
          />
          <BookingsFilterCheck
            color="#538fff"
            label={t`sidebar.bookingStatus.delivered`}
            value="delivered"
            setItemsIncludedList={setItemsIncludedList}
            setItemsExcludedList={setItemsExcludedList}
          />
          <BookingsFilterCheck
            color="#00001c"
            label={t`sidebar.bookingStatus.closed`}
            value="closed"
            setItemsIncludedList={setItemsIncludedList}
            setItemsExcludedList={setItemsExcludedList}
          />
        </BookingsFiltersList>
      </Box>

      {userRoleView === 'operator' && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <BookingsFiltersList color="#000000" label={t`sidebar.bookingStatus.pendingItems`}>
            <BookingsFilterCheck
              color="#ff7976"
              label={t`sidebar.bookingStatus.noCase`}
              value="case"
              iconLabel={<WorkOffIcon className="icon" />}
              setItemsIncludedList={setItemsIncludedList}
              setItemsExcludedList={setItemsExcludedList}
            />
            <BookingsFilterCheck
              color="#ff7976"
              label={t`sidebar.bookingStatus.noPayer`}
              value="payer"
              iconLabel={<MoneyOffIcon className="icon" />}
              setItemsIncludedList={setItemsIncludedList}
              setItemsExcludedList={setItemsExcludedList}
            />
            <BookingsFilterCheck
              color="#ff7976"
              label={t`sidebar.bookingStatus.noInterpreter`}
              value="interpreter"
              iconLabel={<PersonOffIcon className="icon" />}
              setItemsIncludedList={setItemsIncludedList}
              setItemsExcludedList={setItemsExcludedList}
            />
          </BookingsFiltersList>
        </Box>
      )}
    </Box>
  );
}

export default BookingStatus;
