import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormSelectCreatable from '../../../../../components/Form/FormSelectCreatable/FormSelectCreatable';
import { UninsuredAffiliation } from '../../../../../app/types/Entities';

export interface PatientFieldDisplaySelectProps {
  id: string;
  affiliation: UninsuredAffiliation | null;
  affiliations: UninsuredAffiliation[] | undefined | null;
  formatAffiliationToLabel: (affiliation: UninsuredAffiliation) => string;
  setAffiliation: (affiliation: UninsuredAffiliation | null) => void;
  setShowPatientField: (showPatientField: boolean) => void;
  isAffiliationLoading: boolean;
  createMode: boolean;
  setCreateMode: (createMode: boolean) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  userRoleView: string | null;
}

const translationArray = ['booking'];

function PatientFieldDisplaySelect(props: PatientFieldDisplaySelectProps) {
  const {
    id,
    affiliation,
    affiliations,
    formatAffiliationToLabel,
    setAffiliation,
    setShowPatientField,
    isAffiliationLoading,
    createMode,
    setCreateMode,
    setFirstName,
    setLastName,
    userRoleView,
  } = props;

  const { t } = useTranslation(translationArray);

  const options = affiliations ?? [];

  const renderInput = (params: any) => <TextField {...params} label={createMode ? t`newPatient` : t`selectPatient`} />;

  const onCreateOption = (newValue: string) => {
    setShowPatientField(!createMode);
    setCreateMode(!createMode);

    const [newFirstName, newLastName] = newValue.split(' ', 2);
    setFirstName(newFirstName);
    setLastName(newLastName);
  };

  const onSelectOption = (option: UninsuredAffiliation | null) => setAffiliation(option);

  const getOptionValue = (option: UninsuredAffiliation) => option.id;

  return (
    <FormSelectCreatable<UninsuredAffiliation>
      fullWidth
      id={id}
      options={options}
      value={createMode ? null : affiliation}
      loading={isAffiliationLoading}
      disabled={createMode || userRoleView === 'provider'}
      clearOnBlur
      renderInput={renderInput}
      getCreatedOptionLabel={() => t`tooltip.addPatient`}
      getSelectedOptionLabel={formatAffiliationToLabel}
      onCreateOption={onCreateOption}
      onSelectOption={onSelectOption}
      getOptionValue={getOptionValue}
    />
  );
}

export default PatientFieldDisplaySelect;
