import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, FormText, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { DEFAULT_COUNTRY_FOR_LOCATION } from 'src/app/helpers/constants';
import { ErrorsAlert } from 'src/components/ErrorsAlert';
import FormSelectCreatable from 'src/components/Form/FormSelectCreatable/FormSelectCreatable';
import { useAppContext } from 'src/app/context/ModeContext';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { flattenContacts, unflattenContacts } from '../../../../app/helpers/mappers';
import { FormInput } from '../../../../components/Form/FormInput';
import FormWrapper from '../../../../components/Form/FormWrapper';
import FlatContactList from './FlatContactList/FlatContactList';
import FormExpandableField from '../../../../components/Form/FormExpandableField';
import { useForm, useStateArray, useStateDict } from '../../../../app/hooks';
import {
  ClinicsGetParams,
  ClinicsPostError,
  ClinicsPostPayload,
  ClinicsPostResponse,
  ClinicsPutError,
  ClinicsPutPayload,
  ClinicsPutResponse,
  createClinic as apiCreateClinic,
  getClinics,
  getCompanies,
  MUTATION_KEYS,
  updateClinic as apiUpdateClinic,
} from '../../../../app/api';
import { Clinic, FlatContact, Location, Note, ParentCompany } from '../../../../app/types/Entities';
import { BUTTON_STATE, COMPANY_TYPES, SEND_METHODS } from '../../../../app/helpers/enum';
import LocationSection from './LocationSection/LocationSection';
import { Errors } from '../../../../app/types/DataStructures';
import NotesListField from './NotesListField/NotesListField';

export interface ClinicFieldProps {
  showClinicField: boolean;
  setShowClinicField: (display: boolean) => void;
  clinic: Clinic | null;
  setClinic: (clinic: Clinic | null) => void;
  onUpdateSuccess?: (clinic?: Clinic) => void;
}

const translationArray = ['booking', 'forms', 'languages'];
const formPrefix = 'clinic';

function ClinicField(props: ClinicFieldProps) {
  const { showClinicField, setShowClinicField, clinic, setClinic, onUpdateSuccess } = props;

  // Utils
  const { p, eventValueExtractor } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);
  const { userRoleView } = useAppContext();

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<ClinicsPutError>({});
  const [createMode, setCreateMode] = useState<boolean>(false);

  const [showNotes, setShowNotes] = useState<boolean>(false);
  useEffect(() => {
    if (!showClinicField) {
      setShowNotes(false);
    }
  }, [showClinicField]);

  const [clinicName, setClinicName] = useState<string | undefined>();
  const [parentCompany, setParentCompany] = useState<ParentCompany | null>(null);
  const [location, setLocation] = useState<Location | undefined>();
  const updateLocation = (fields: Partial<Location>) => setLocation((prev) => ({ ...prev, ...fields }));

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  const {
    values: notes,
    set: setNotes,
    push: pushNote,
    replaceAt: replaceNoteAt,
    removeAt: stateRemoveNoteAt,
  } = useStateArray<Note>([]);

  const removeNoteAt = (index: number) => {
    if (notes.length <= 1) {
      setShowNotes(false);
    }
    stateRemoveNoteAt(index);
  };

  useEffect(() => {
    setClinicName(clinic?.name);
    setParentCompany(clinic?.parent_company ?? null);
    setLocation(clinic?.locations?.[0]);
    setFlatContacts(flattenContacts(clinic?.contacts) ?? []);
    setNotes(clinic?.notes ?? []);
  }, [clinic, setFlatContacts, setNotes]);

  useEffect(() => {
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // Query
  const [clinicFilters] = useStateDict<ClinicsGetParams>({});
  const [clinicsRefetchEnabled, setClinicsRefetchEnabled] = useState<boolean>(true);

  const {
    data: clinics,
    isLoading: isClinicLoading,
    refetch: refetchClinics,
  } = useQuery([MUTATION_KEYS.COMPANIES, clinicFilters], () => getClinics(clinicFilters), {
    enabled: clinicsRefetchEnabled,
  });

  useEffect(() => {
    if (clinics) {
      setClinicsRefetchEnabled(false);
    }
  }, [clinics]);

  const [parentCompaniesRefetchEnabled, setParentCompaniesRefetchEnabled] = useState<boolean>(true);
  const { data: parentCompanies, isLoading: isParentCompaniesLoading } = useQuery(
    [MUTATION_KEYS.COMPANIES, {}],
    () => getCompanies({}),
    { enabled: parentCompaniesRefetchEnabled },
  );

  useEffect(() => {
    if (parentCompanies) {
      setParentCompaniesRefetchEnabled(false);
    }
  }, [parentCompanies]);

  // On saving
  const { mutateAsync: mutateAsyncCreateClinic } = useMutation<
    ClinicsPostResponse,
    ClinicsPostError,
    ClinicsPostPayload
  >(apiCreateClinic);

  const { mutateAsync: mutateAsyncUpdateClinic } = useMutation<
    ClinicsPutResponse,
    ClinicsPutError,
    Parameters<typeof apiUpdateClinic>
  >((args) => apiUpdateClinic(...args));

  // On save functions
  // validate
  const validate = () => {
    const validationErrors = [];

    if (createMode) {
      if (!clinicName) {
        validationErrors.push(t`errors.noClinicName`);
      }
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  // On create
  const createClinic = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? [
            {
              ...location,
              country: DEFAULT_COUNTRY_FOR_LOCATION,
            },
          ]
        : undefined;

    const payload: ClinicsPutPayload = {
      name: clinicName!,
      parent_company: parentCompany?.id!,
      on_hold: false,
      type: COMPANY_TYPES.CLINIC,
      send_method: SEND_METHODS.EMAIL,
      contacts: unflattenContacts(flatContacts),
      locations: payloadLocation ?? [],
      notes,
    };

    toast
      .promise(mutateAsyncCreateClinic(payload), {
        pending: t`progress.clinicCreate.started` as string,
        success: t`progress.clinicCreate.success` as string,
        error: t`progress.clinicCreate.error` as string,
      })
      .catch((err: ClinicsPostError) => {
        setErrors(err);
        console.error(`Errors when creating clinic "${clinicName}"`, err);
        return Promise.reject();
      })
      .then(async (createdClinicId) => {
        return [await refetchClinics(), createdClinicId] as const;
      })
      .then(([refetchData, createdClinicId]) => {
        const { data: newClinics } = refetchData;
        const newlyCreatedClinic = newClinics?.find((cli) => cli.id === createdClinicId);
        setShowClinicField(false);
        setCreateMode(false);
        onUpdateSuccess?.(newlyCreatedClinic);
      });
  };

  // On update
  const updateClinic = () => {
    setErrors({});

    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? [
            {
              ...location,
              country: DEFAULT_COUNTRY_FOR_LOCATION,
            },
          ]
        : undefined;

    const payload: ClinicsPutPayload = {
      name: clinicName,
      parent_company: parentCompany?.id,
      on_hold: clinic?.on_hold,
      type: clinic?.type,
      send_method: clinic?.send_method,
      contacts: unflattenContacts(flatContacts),
      locations: payloadLocation ?? [],
      notes,
    };

    toast
      .promise(mutateAsyncUpdateClinic([clinic?.id!, payload]), {
        pending: t`progress.clinicUpdate.started` as string,
        success: t`progress.clinicUpdate.success` as string,
        error: t`progress.clinicUpdate.error` as string,
      })
      .catch((err) => {
        if (err) setErrors(err);
        return Promise.reject();
      })
      .then(() => refetchClinics())
      .then((refetchData) => {
        const { data: newClinics } = refetchData;
        const updatedClinic = newClinics?.find((iterClinic) => iterClinic.id === clinic?.id);
        onUpdateSuccess?.(updatedClinic);
      });
  };

  const onSave = () => (createMode ? createClinic() : updateClinic());

  // Display
  const label = <h5>{t`clinic`} *</h5>;

  const display = (
    <FormSelectCreatable<Clinic>
      fullWidth
      id={p`clinic`}
      options={clinics ?? []}
      value={createMode ? null : clinic}
      loading={isClinicLoading}
      disabled={createMode || userRoleView === 'provider'}
      clearOnBlur
      renderInput={(params) => <TextField {...params} label={createMode ? t`newClinic` : t`selectClinic`} />}
      getCreatedOptionLabel={() => t`tooltip.addClinic`}
      getSelectedOptionLabel={(clinicOption) => clinicOption.name}
      onCreateOption={(newValue) => {
        setShowClinicField(!createMode);
        setCreateMode(!createMode);

        setClinicName(newValue);
      }}
      onSelectOption={(option) => setClinic(option as Clinic)}
      getOptionValue={(option) => option.id}
    />
  );

  const onCancel = () => {
    setShowClinicField(false);
    setCreateMode(false);
  };

  const footer = (
    <Row>
      <Col>
        <Button className="action-button me-4" color="primary" onClick={onSave} block>
          {t`saveClinic`}
        </Button>
      </Col>

      <Col>
        <Button className="action-button" color="cancel" onClick={onCancel} block>
          {t`cancel`}
        </Button>
      </Col>
      <Col>
        <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
      </Col>
    </Row>
  );

  return (
    <FormExpandableField
      formPrefix={p``}
      isOpen={showClinicField}
      toggleOpen={setShowClinicField}
      canClose={!createMode}
      openButtonState={
        !clinic || createMode || userRoleView === 'provider' ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE
      }
      openButtonTooltip={showClinicField ? t`tooltip.hideClinic` : t`tooltip.viewClinic`}
      label={label}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col md={5}>
          <FormInput label={t`clinic`} value={clinicName ?? ''} onChange={eventValueExtractor(setClinicName)} />
        </Col>
        <Col>
          <FormSelect
            id={p`parentCompany`}
            options={parentCompanies}
            loading={isParentCompaniesLoading}
            value={parentCompany}
            onChange={(_event, titleOption) => setParentCompany(titleOption)}
            renderInput={(params) => <TextField placeholder={t`parentCompany`} {...params} />}
            getOptionLabel={(c) => c.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label="Address" className="pb-2">
            <LocationSection
              errors={errors.locations as Errors<Location> | undefined}
              location={location}
              update={updateLocation}
            />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={t`contactInformation`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <NotesListField
            formPrefix={p`clinic-notes`}
            labelText={t`clinicNotes`}
            showNotesList={showNotes}
            setShowNotesList={setShowNotes}
            notesList={notes}
            pushNote={pushNote}
            replaceNoteAt={replaceNoteAt}
            removeNoteAt={removeNoteAt}
          />
        </Col>
      </Row>
      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <ErrorsAlert errorsArray={errors.validation_errors} />
    </FormExpandableField>
  );
}

export default ClinicField;
